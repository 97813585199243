<template>
  <NuxtLink
    :class="{ 'slot-disabled': isDisabled }"
    :to="
      localePath({
        path: `/casino/game/${item.id}-${slugify(item.name)}`,
      })
    "
    class="slots__body-grid-item"
  >
    <div class="die">
      <div v-if="isNew" class="die_item die--new">
        <span>{{ $t("page.casino.status.new") }}</span>
      </div>
    </div>
    <img :src="`${item.image && item.image.startsWith('http') ? '' : $config.public.CDN_URL}${item.image}`" class="image" alt="" loading="lazy" />
    <div class="slots__body-grid-item-overlay">
      <div class="slots__body-grid-item-provider">
        {{ item.provider }}
      </div>
      <div class="slots__body-grid-item-btns">
        <NuxtLink
          :class="{ 'slot-disabled': isDisabled }"
          :to="
            localePath({
              path: `/casino/game/${item.id}-${slugify(item.name)}`,
            })
          "
          class="slots__body-grid-item-btn"
        >
          <img src="@/assets/svg/card/slot/play.svg?url" cl alt="" />
        </NuxtLink>
        <div class="slots__body-grid-item-btn mobile-play">
          <img src="@/assets/svg/card/slot/play.svg?url" cl alt="" />
        </div>
        <NuxtLink
          :class="{ 'slot-disabled': isDisabled }"
          v-if="type === 'slots' || item.type === 'slots'"
          :to="
            localePath({
              path: `/casino/game/${item.id}-demo-${slugify(item.name)}`,
            })
          "
          class="slots__body-grid-item-btn slots__body-grid-item-btn--demo"
        >
          <img src="@/assets/svg/card/slot/demo.svg?url" alt="" />
        </NuxtLink>
      </div>
      <h2 class="slots__body-grid-item-title">{{ item.name }}</h2>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { slugify } from "~~/composables/misc";

const localePath = useLocalePath();

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: "live",
  },
});

const { $auth } = useNuxtApp();
const isDisabled = computed(() => !$auth.loggedIn && props.item.type === "live");

const isNew = computed(() => {
  const now = new Date();
  const created = new Date(props.item.created_at);
  const period = now.valueOf() - created.valueOf();
  const day = 1000 * 60 * 60 * 24;
  const days = Math.floor(period / day);
  const diff = days < 15;
  return diff;
});
</script>

<style lang="scss">
.slots__body-grid {
  &-item {
    aspect-ratio: 204/136;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .die {
      position: absolute;
      top: 4px;
      left: 4px;

      span {
        position: relative;
      }

      &_item {
        position: relative;
        box-shadow: inset 0px 2px 5px rgba(255, 255, 255, 0.4);
        padding: 6px 16px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        font-style: italic;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #ffffff;

        @media screen and (max-width: 980px) {
          font-size: 10px;
          line-height: 12px;
          padding: 5px 12px;
        }
      }

      &--new {
        background: linear-gradient(90deg, #64d662 0.06%, #16b31d 98.49%);

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 13px;
          height: 88px;
          background: linear-gradient(180deg, #b4ffcd 0%, rgba(255, 255, 255, 0.3) 100%);
          opacity: 0.6;
          filter: blur(3.5px);
          transform: rotate(15deg);
          top: -23px;
          left: -40%;
          animation: move-right 5s infinite;

          @keyframes move-right {
            0% {
              left: -40%;
            }

            20% {
              left: -40%;
            }

            40% {
              left: 120%;
            }

            100% {
              left: 120%;
            }
          }
        }
      }
    }

    .image {
      width: 100%;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .slots__body-grid-item-overlay {
        opacity: 1;
      }
    }

    &-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: #2b2544, $alpha: 0.9);
      opacity: 0;
      z-index: 1;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      transition: all 100ms linear;
    }

    &-provider {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      /* identical to box height */

      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      color: #ffffff;

      opacity: 0.5;
    }

    &-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      .mobile-play {
        display: flex;

        @media screen and (min-width: 980px) {
          display: none;
        }
      }

      @media screen and (max-width: 980px) {
        a,
        button {
          display: none;
        }
      }
    }

    &-btn {
      background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);

      color: #ffffff;
      height: 52px;
      width: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      transition: all 0.2s linear;

      img {
        width: auto;
        height: 40%;
        margin-left: 4px;

        path {
          fill: #fff;
        }
      }

      &:hover,
      &:active {
        filter: brightness(1.15);
      }

      &--demo {
        background: linear-gradient(45deg, #ffc658 0%, #f4a100 100%);

        img {
          height: 30%;
          margin: 0;
        }
      }
    }

    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
